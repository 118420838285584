<script setup lang="ts" generic="TModel extends unknown = unknown[]">
import { ErrorMessage, useField } from 'vee-validate'
import Multiselect from '@vueform/multiselect'
import type { MultiselectOption, Rules } from '~/components/BaseForm/types'

defineOptions({
  inheritAttrs: false,
})
type OptionType = TModel extends Array<infer R> ? R : TModel

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    height?: number
    infoText?: string
    inputHtml?: string
    labelHtml?: string
    labelText?: string
    modelValue?: TModel | null
    options?: OptionType[] | MultiselectOption<OptionType>[]
    name: string
    rules?: Rules
    showErrorText?: boolean
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    height: 0,
    infoText: '',
    inputHtml: '',
    labelHtml: '',
    labelText: '',
    modelValue: null,
    options: () => [],
    rules: '',
    showErrorText: true,
    wrapperClass: 'w-full mb-4',
  },
)
const emits = defineEmits<{
  clear: [Multiselect]
  close: []
  deselect: [OptionType]
  open: []
  select: [OptionType]
  'update:model-value': [string]
}>()

const rules = toRef(props, 'rules')
const {
  value: inputValue,
  handleChange,
  errors,
} = useField<TModel>(props.name, rules.value, {
  initialValue: props.modelValue as TModel,
})

const customHeight = computed(() => `${props.height}px`)

const onChange = (value: string): void => {
  handleChange(value)
  emits('update:model-value', value)
}

const isError = computed(() => Boolean(errors.value.length))

const onClear = (event: Multiselect): void => {
  emits('clear', event)
}

const onSelect = (event: OptionType): void => {
  emits('select', event)
}
const onDeselect = (event: OptionType): void => {
  emits('deselect', event)
}

const onOpen = (): void => {
  emits('open')
}

const onClose = (): void => {
  emits('close')
}
defineSlots<{
  'multiselect-placeholder'(): any
  'multiselect-afterlist'(): any
  'multiselect-beforelist'(): any
  'multiselect-multiplelabel'(p: { values: object | any[] }): any
  'multiselect-nooptions'(): any
  'multiselect-noresults'(): any
  'multiselect-singlelabel'(p: { value: any }): any
  'multiselect-option'(p: { option: any }): any
  'multiselect-tag'(p: {
    option: any
    handleTagRemove: (option: any, e: Event) => void
  }): any
  'multiselect-caret'(): any
  'multiselect-clear'(p: { clear: () => void }): any
  'multiselect-spinner'(): any
  'multiselect-infinite'(): any
}>()
</script>

<template>
  <div :class="wrapperClass" :data-testid="`multiselect-${name}`">
    <label
      v-if="labelText"
      class="base-form-label"
      data-testid="multiselect-label-text"
    >
      <BaseFormComponentsBaseFormLabel
        :label="labelText"
        :label-html="labelHtml"
      />
      <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
    </label>

    <Multiselect
      :id="name"
      v-model="inputValue"
      :class="[{ 'base-form--hasError': isError }]"
      :options="options"
      :disabled="disabled"
      autocomplete="off"
      data-testid="multiselect"
      v-bind="$attrs"
      @change="onChange"
      @clear="onClear"
      @close="onClose"
      @deselect="onDeselect"
      @open="onOpen"
      @select="onSelect"
    >
      <template #placeholder>
        <slot name="multiselect-placeholder" />
      </template>

      <template #afterlist>
        <slot name="multiselect-afterlist" />
      </template>

      <template #beforelist>
        <slot name="multiselect-beforelist" />
      </template>

      <template #multiplelabel="{ values }">
        <slot name="multiselect-multiplelabel" :values="values" />
      </template>

      <template #nooptions>
        <slot name="multiselect-nooptions" />
      </template>

      <template #noresults>
        <slot name="multiselect-noresults" />
      </template>

      <template #singlelabel="{ value }">
        <slot name="multiselect-singlelabel" :value="value" />
      </template>

      <template #option="{ option }">
        <slot name="multiselect-option" :option="option" />
      </template>

      <template #tag="{ option, handleTagRemove }">
        <slot
          name="multiselect-tag"
          :option="option"
          :handle-tag-remove="handleTagRemove"
        />
      </template>

      <template #caret>
        <slot name="multiselect-caret" />
      </template>

      <template #clear="{ clear }">
        <slot name="multiselect-clear" :clear="clear" />
      </template>

      <template #spinner>
        <slot name="multiselect-spinner" />
      </template>

      <template #infinite>
        <slot name="multiselect-infinite" />
      </template>
    </Multiselect>

    <BaseFormComponentsBaseStringToHtml
      v-if="inputHtml"
      :str-html="inputHtml"
    />

    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="$t(infoText)" />

    <ErrorMessage
      v-if="showErrorText"
      :name="name"
      as="span"
      class="base-form--error"
    />
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
body .multiselect {
  height: v-bind(customHeight);
  min-height: 51px;
  @apply border-gray-300 rounded-sm bg-transparent;
}
.multiselect-wrapper {
  height: 100%;
}
:root {
  --ms-max-height: 15rem;
  --ms-tag-bg: #d4a45f;
  --ms-option-bg-selected: #d4a45f;
  --ms-option-bg-selected-pointed: #d4a45f;
  --ms-ring-color: #dbbc8f40;
}
.vti__dropdown:hover {
  background: transparent;
}
</style>
